var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"amazake_area"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('section',{staticClass:"menu",attrs:{"id":"amazake_menu"}},[_c('div',{staticClass:"container"},[_vm._m(3),_c('div',{staticClass:"row menu_list"},_vm._l((_vm.menuItems),function(menu,i){return _c('div',{key:i,staticClass:"col-lg-4"},[_c('img',{attrs:{"src":require('../assets/' + menu.img),"alt":""}}),_c('h3',[_vm._v(_vm._s(menu.name))]),_c('p',[_vm._v("\n            "+_vm._s(menu.text)+"\n          ")]),_c('span',{staticClass:"price"},[_vm._v(" ¥"+_vm._s(menu.price)+" ")])])}),0)])]),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_catch"},[_c('img',{attrs:{"src":require("../assets/amazake_main.jpg"),"alt":""}}),_c('div',{staticClass:"main_catch_cont"},[_c('img',{attrs:{"src":require("../assets/amazake_logo.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"amazake_nav"},[_c('a',{attrs:{"href":"#amazake_concept"}},[_vm._v("\n      CONCEPT\n    ")]),_c('a',{attrs:{"href":"#amazake_menu"}},[_vm._v("\n      MENU\n    ")]),_c('a',{attrs:{"href":"#amazake_map"}},[_vm._v("\n      MAP\n    ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"concept",attrs:{"id":"amazake_concept"}},[_c('div',{staticClass:"container"},[_c('h2',[_vm._v("\n        CONCEPT\n        "),_c('small',[_vm._v("コンセプト")])]),_c('p',{staticClass:"text-center"},[_vm._v("\n        あま酒は、美白・美肌にも効果が期待できる、おいしくキレイになれるスーパードリンクです。「飲む点滴」と呼ばれるほど栄養価の高いドリンクとして、今注目を集めています。"),_c('br'),_c('br'),_vm._v("\n        「あま酒研究所」では、美容や健康、さらには疲労回復の効果はもちろん、米麹からつくったあま酒をベースに、野菜やフレッシュフルーツなど、独自のブレンドでつくったあま酒を提供します。"),_c('br'),_c('br'),_vm._v("\n        米麹本来の優しい風味とほのかな甘味、野菜やフレッシュフルーツの味わいが合わさり、ここでしか味わえない新感覚のあま酒を飲んで、身体の中からキレイになりませんか？\n      ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v("\n        MENU\n        "),_c('small',[_vm._v("メニュー")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"amazake_map_area"},[_c('div',{attrs:{"id":"amazake_map"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{},[_c('div',{staticClass:"container"},[_c('h2',[_c('img',{attrs:{"width":"300","src":require("../assets/amazake_logo.png"),"alt":""}})]),_c('table',{staticClass:"table"},[_c('tr',[_c('th',[_vm._v("店舗名称")]),_c('td',[_vm._v("\n            あま酒研究所\n          ")])]),_c('tr',[_c('th',[_vm._v("住所")]),_c('td',[_vm._v("\n            〒101-0021 東京都千代田区外神田4-4-2 HULIC ＆ New AKIHABARA 10F"),_c('br'),_vm._v("\n            （JR山手線 秋葉原駅 徒歩5分）\n          ")])]),_c('tr',[_c('th',[_vm._v("問い合わせ電話番号")]),_c('td',[_vm._v("03-5244-4399")])]),_c('tr',[_c('th',[_vm._v("営業時間")]),_c('td',[_vm._v("\n            平日　18:00～23:00（L.O 22:30）"),_c('br'),_vm._v("\n            土日　12:00〜16:00（L.O 15:30）"),_c('br'),_vm._v("\n            18:00～23:00（L.O 22:30）\n          ")])]),_c('tr',[_c('th',[_vm._v("席")]),_c('td',[_vm._v("座席 / 26席・立席 / 18席（合計:44席）")])]),_c('tr',[_c('th',[_vm._v("提供期間")]),_c('td',[_vm._v("2019年9月6日（金）〜2019年9月30日（月）")])]),_c('tr',[_c('th',[_vm._v("注意事項")]),_c('td',[_vm._v("\n            ※KURAND SAKE MARKET\n            秋葉原店のスペースを活用して営業するスタイルになります。"),_c('br'),_vm._v("\n            ※平日昼は、テイクアウト専用となりますが、店内に着席してお飲みいただくことも可能です。"),_c('br'),_vm._v("\n            ※平日夜、土日祝の昼夜は、日本酒専門店「KURAND SAKE\n            MARKET」として営業します。"),_c('br'),_vm._v("\n            ※「KURAND SAKE\n            MARKET」の営業時間内でも、同商品の注文（有料）は可能です。\n          ")])])])])])
}]

export { render, staticRenderFns }