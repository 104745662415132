<template>
  <div id="app">
    <div class="loading_wrap" v-if="storeLoading">
      <div class="loading_ico spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-if="drawerOpened" class="drawer_wrap" @click="drawerClose"></div>
    <transition
      name="drawer"
      v-on:after-leave="toggleShown"
      v-on:after-enter="toggleShown"
    >
      <div v-if="drawerOpened" class="drawer_area">
        <h2>STORE</h2>
        <div class="store_list">
          <router-link
            class="drawer_link"
            v-for="(store, i) in storeList"
            :key="i"
            :to="'/' + store.slug"
          >
            {{ store.title.rendered }}
          </router-link>
          <h2><router-link :to="'/blog'">NEWS</router-link></h2>
          <!-- <h2>
            <router-link :to="'/part-time-job'">RECRUIT</router-link>
          </h2> -->
          <!-- <h2><a href="https://kurand.jp/en" target="_blank">ENGLISH</a></h2> -->
        </div>
      </div>
    </transition>

    <nav id="nav" v-if="$route.name !== 'amazake'">
      <router-link class="logo" to="/">
        <img class="logo" alt="SAKE MARKET" src="./assets/logo.svg" />
      </router-link>
      <!-- <a
        class="ml-auto mr-3 btn btn-outline-dark"
        href="https://sakemarket.kurand.jp/en/"
        target="_brank"
        >EN</a
      > -->
      <button class="drawer_btn" @click="$store.commit('changeDrawer', true)">
        <i class="fas fa-bars"></i>
      </button>
    </nav>

    <router-view />

    <!-- <section class="faq" v-if="false">
      <div class="faq_inner">
        <h2>FAQ</h2>

        <div class="faq_cont" v-for="(question, i) in questions" :key="i">
          <div class="question">
            {{ question.q }}
          </div>
          <div
            class="answer"
            v-html="question.a.replace(/\r?\n/g, '<br>')"
          ></div>
        </div>
      </div>
    </section> -->

    <div class="item_list">
      <h2>採用情報</h2>
      <h3>Recruit</h3>
      <p class="text-center">
        SAKE MARKETでは一緒にお店を盛り上げてくれる<br />
        アルバイトスタッフ・社員を募集しております。
      </p>
      <a href="https://9p5zi4kf.jbplt.jp/" class="my_btn">
        ご応募、お問い合わせはこちらから<i class="fas fa-chevron-right"></i>
      </a>
    </div>

    <footer v-if="$route.name !== 'amazake'">
      <div class="sns_logo">
        <a href="https://www.instagram.com/sakemarket/">
          <i class="fab fa-instagram"></i>
        </a>
        <a href="https://line.me/R/ti/p/%40itl6912i">
          <i class="fab fa-line"></i>
        </a>
        <a href="https://twitter.com/sakemarket?lang=ja">
          <i class="fab fa-twitter"></i>
        </a>
        <a href="https://www.facebook.com/kurandsakemarket/">
          <i class="fab fa-facebook-f"></i>
        </a>
      </div>

      <div class="text-center">
        <img width="146" src="./assets/foot_logo.svg" alt="" />
      </div>

      <div class="bottom_menu">
        <a href="https://kurand.jp/pages/privacy/" target="_blank">
          プライバシーポリシー
        </a>
        <a href="https://liquor-innovation.co.jp/" target="_blank">
          運営会社
        </a>
      </div>
    </footer>

    <router-view name="cta"></router-view>

    <transition name="modal">
      <div
        class="modal_mask"
        v-if="modalShow"
        @click.self="$store.commit('changeModal', false)"
      >
        <div class="modal_area">
          <a
            class="close_btn"
            href="#"
            @click.prevent="$store.commit('changeModal', false)"
            ><i class="fas fa-times"></i
          ></a>
          <h2>
            <img src="./assets/key_text.svg" alt="" />
          </h2>

          <div class="store_list row">
            <div class="col-lg-6" v-for="(store, i) in storeList" :key="i">
              <div class="store_cont">
                <a :href="store.acf.airreserve">
                  <h3>{{ store.title.rendered }}</h3>
                  <p v-html="store.acf.address"></p>
                  <p>予約専用電話番号 {{ store.acf.reserve_tel }}</p>

                  <i class="fas fa-chevron-right store_link"></i
                ></a>
              </div>
            </div>
          </div>
          <!-- <h3>
            WEBからの事前予約にて来店された方には<br />
            おつまみ1品プレゼント！
          </h3>
          <div class="row">
            <div class="col-lg-6 mb-4">
              <img src="./assets/otsumami.jpg" alt="" />
            </div>
            <div class="col-lg-6">
              <h4>鶏皮ポン酢</h4>
              <p>
                ローストした鶏皮をスライスし柚子風味のポン酢で味付け。さっぱりした味わいがクセになります。<br />
                合う日本酒：大垣わたなべ
              </p>
              <small>
                ※提供するおつまみは、入荷状況やお客さまのお持ち込みのおつまみとの兼ね合いで、変更する場合がございます。<br />
                ※提供するおつまみは、グループの人数に合わせた量で提供します。<br />
                ※ページ内の画像は全てイメージです。
              </small>
            </div>
          </div> -->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import '@fortawesome/fontawesome-free/css/all.css'
import { mapState } from 'vuex'

export default {
  metaInfo() {
    return {
      bodyAttrs: {
        class: this.modalShow || this.drawerOpened ? 'body_fixed' : '',
      },
    }
  },
  data() {
    return {
      instaPhotos: [],
      questions: [
        {
          q: '何がお店に用意されていますか？',
          a: `お水、お水用コップ、電子レンジ、取り皿、お箸、スプーン、フォーク、おしぼり、お醤油、塩、コショウなどの調味料もございます。全て無料です。また、以下はご使用が可能ですので、気軽にお声がけくださいませ。
電子レンジ	スタッフに声掛けいただければ、暖めて席までお持ちします。お気軽にご利用ください。`,
        },
        {
          q: '熱燗はすることができますか？',
          a: `酒燗器を用意しておりますので、熱燗もできます。`,
        },
        {
          q: '禁止行為はありますか？',
          a: `皆さまが日本酒を楽しむことができるように、以下を禁止行為とさせて頂いております。ご了承頂ければ幸いです。

・お水のコップでお酒を飲まないこと。注がないこと。
・酒器にギリギリまで注がないこと。
・冷蔵庫前で、お酒を飲まないこと。必ずお席にお戻りください。
・吐くまで飲まない。倒れるまで飲まないこと。
・他のお客様に、迷惑をかけないこと。
・お酒のお持ち込みはご遠慮下さい。（ソフトドリンク類のお持ち込みは可能）
・ビルの共有部分（エレベーター、階段、入口）に嘔吐してしまうと、特殊清掃代金4万円をお支払いいただくことになります。
・飲み過ぎ・酔いすぎと店主が判断したら退場させていただきます。`,
        },
      ],
    }
  },
  methods: {
    drawerClose() {
      this.$store.commit('changeDrawer', false)
    },
    toggleShown() {
      //this.$store.commit('changeDrawer', !this.drawerOpened)
    },
  },
  watch: {
    $route() {
      this.$store.commit('changeModal', false)
      this.$store.commit('changeDrawer', false)
    },
  },
  mounted() {
    window.onscroll = () => {
      if (
        window.pageYOffset + window.parent.screen.height >
        document.body.clientHeight - 100
      ) {
        $('.bottom_fix').fadeOut()
      } else {
        $('.bottom_fix').fadeIn()
      }
    }

    this.$store.dispatch('getStoreList')

    // document.dispatchEvent(new Event('x-app-rendered'))
    setTimeout(() => {
      //this.$gtm.enable(true)
    }, 2000)
  },
  computed: {
    isHome() {
      return this.$route.name === 'home'
    },
    ...mapState(['storeLoading', 'storeList', 'modalShow', 'drawerOpened']),
  },
  directives: {
    ClickOutside,
  },
}
</script>

<style lang="scss">
@import 'slick-carousel/slick/slick.scss';
// @import 'bootstrap/scss/_functions.scss';
// @import './style/_variable.scss';
@import 'bootstrap-honoka/scss/honoka/_override.scss';
@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';

html {
  font-size: 18px;
  @include media-breakpoint-down(lg) {
    font-size: 14px;
  }
}
body {
  background-color: #f4f4f4;
  overflow-x: hidden;
  &.body_fixed {
    overflow: hidden;
  }
}
#app {
  background-color: #f4f4f4;

  //-webkit-overflow-scrolling: touch;
}
img {
  max-width: 100%;
}

a:hover {
  img {
    opacity: 0.7;
  }
}

.loading_wrap {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#fff, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  .loading_ico {
    width: 4rem;
    height: 4rem;
  }
}

.drawer_wrap {
  z-index: 39;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.6);
}

.drawer_area {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 40;
  height: 100%;

  background: #fff;
  transition: all 0.3s ease-in-out 0s;
  transform: translateX(0%);
  padding: 15px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  &.drawer-enter,
  &.drawer-leave,
  &.drawer-leave-active {
    transform: translateX(100%);
  }
  h2 {
    margin-bottom: 1rem;
    @include media-breakpoint-down(lg) {
      font-size: 1.111rem;
    }
  }
  .drawer_link {
    display: block;
    border-top: 1px solid $gray-300;
    padding: 0.8rem;
  }
}

#nav {
  background-color: #fff;
  height: 86px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    height: 100%;
  }
  .drawer_btn {
    font-size: 20px;
    border: none;
    background: none;
  }
}

.top_catch {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  position: relative;
  .main_slide {
    position: relative;
    height: 100%;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
  .key_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    color: #fff;
    text-align: center;
    img {
      width: 507px;
    }
    span {
      display: block;
      margin-top: 30px;
      font-size: 3rem;
    }
  }
}

.concept_area {
  padding-top: 95px;
  .row {
    justify-content: space-between;
    align-items: flex-end;
    h2 {
      font-size: 4.722rem;
    }
    h3 {
      font-size: 1.555rem;
      font-family: $font-family-serif;
      line-height: 1.8;
      font-weight: 100;
    }
    p {
      line-height: 1.8;
    }

    &.concept01 {
      margin-bottom: 150px;
      img.catch01 {
        width: 633px;
        @include media-breakpoint-up(lg) {
          max-width: none;
        }
      }
    }

    &.concept02 {
      margin-bottom: 150px;
      flex-wrap: wrap;
      align-items: flex-start;
      @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
      }
    }

    .price_table {
      width: 100%;
      margin-bottom: 20px;
      td,
      th {
        padding: 15px;
        border-bottom: 1px solid #000;
      }

      td {
        font-size: 1.333rem;
        white-space: nowrap;
      }
    }
  }
}

.my_btn {
  background-color: #fbee6c;
  border: 2px solid #000;
  padding: 15px 0px;
  text-align: center;
  display: block;
  font-weight: bold;
  position: relative;
  width: 350px;
  max-width: 100%;
  margin: auto;
  i {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%) translateX(-50%);
  }
}

.banner_area {
  justify-content: center;
  text-align: center;
  & > div {
    margin-bottom: 30px;
  }
}

.howto {
  background-image: url('./assets/kokuban.jpg');
  padding: 120px 0;
  background-size: cover;
  font-family: '游ゴシック体', YuGothic;
  .container {
    @include media-breakpoint-down(lg) {
      //padding: 0;
    }
  }
  h2 {
    text-align: center;
    margin-bottom: 80px;
    img {
      margin-left: 60px;
      @include media-breakpoint-down(lg) {
        width: 270px;
        margin-left: 30px;
      }
    }
  }
  h3 {
    background-image: url('./assets/howto_h_bg.png');
    font-size: 1.555rem;
    text-align: center;
    background-repeat: no-repeat;
    background-position-x: 50%;
    margin-bottom: 50px;
    color: #fff;
    padding-top: 20px;
    padding-bottom: 38px;
    background-size: contain;
    @include media-breakpoint-down(lg) {
      font-size: 1rem;
      padding-top: 14px;
      margin-right: 15px;
      margin-left: 15px;
    }
  }
  .row {
    &:not(:last-of-type) {
      margin-bottom: 100px;
    }
    & > div {
      img {
        margin-bottom: 20px;
      }
      text-align: center;
      color: #fff;
      p {
        font-size: 0.888rem;
      }
    }
  }
}

.store {
  background-image: url('./assets/store_bg.jpg');
  background-size: cover;
  color: #fff;
  position: relative;
  padding-top: 76px;
  padding-bottom: 200px;
  margin-top: 200px;
  @include media-breakpoint-down(lg) {
    padding-top: 0;
    margin-top: 0px;
  }
  .store_img {
    position: absolute;
    right: 0;
    top: -100px;
    @include media-breakpoint-down(lg) {
      position: static;
      margin-bottom: 30px;
    }
  }

  h2 {
    margin-bottom: 95px;
    span {
      display: block;
      margin-bottom: 20px;
    }
    img {
      display: block;
    }
  }
}

.store_list {
  & > div {
    .store_cont {
      padding-top: 35px;
      padding-bottom: 35px;
      border-bottom: 1px solid rgba(#fff, 0.6);
      position: relative;
      a {
        color: #fff;
      }
      .store_link {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%) translateX(-50%);
      }
    }
    h3 {
      font-size: 1.444rem;
    }
    p {
      font-size: 0.888rem;
      margin-bottom: 0;
    }
  }
}

.faq {
  .faq_inner {
    padding: 78px;
    position: relative;
    top: -100px;
    background-color: #fff;
    width: 800px;
    max-width: 100%;
    margin: auto;
    box-shadow: 0 0 1px 2px rgba(#000, 0.1);
    h2 {
      font-size: 2.222rem;
      margin-bottom: 57px;
      text-align: center;
    }

    .faq_cont {
      &:not(:last-child) {
        margin-bottom: 80px;
      }
      .question,
      .answer {
        position: relative;
        &:before {
          position: absolute;
          font-weight: bold;
          left: -36px;
          top: -10px;
        }
      }

      .question {
        font-size: 1.444rem;
        margin-bottom: 20px;
        &:before {
          content: 'Q';
          font-size: 2rem;
        }
      }

      .answer {
        &:before {
          content: 'A';
          font-size: 2rem;
          color: #8b8b8b;
        }
      }
    }
  }
}

.instagram {
  padding-top: 90px;
  margin-bottom: 90px;
  h2 {
    text-align: center;
    font-size: 2.222rem;
    margin-bottom: 50px;
  }
  .insta_account {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    img {
      height: auto;
      flex-shrink: 0;
      flex-basis: 50px;
      height: 50px;
      margin-right: 10px;
      border-radius: 50%;
      padding: 5px;
      border: 1px solid $gray-300;
    }
  }
  .insta_wrap {
    & > div {
      margin-bottom: 30px;
      a {
        display: block;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

footer {
  padding-top: 60px;
  .foot_menu {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    flex-wrap: wrap;
    a {
      margin: 0 20px;
      margin-bottom: 20px;
      font-size: 0.888rem;
    }
  }
  .sns_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    a {
      margin: 0 20px;
      font-size: 2rem;
      i,
      img {
        display: block;
      }
    }
  }
  .text-center {
    margin-bottom: 100px;
  }
  .bottom_menu {
    background-color: #e5e5e5;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    a {
      margin: 0 10px;
      font-size: 0.722rem;
    }
  }
}

.bottom_fix {
  background: rgba(#000, 0.6);
  text-align: center;
  padding: 15px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .my_btn {
    margin: 0 10px;
  }
}

.modal_mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.3);
  z-index: 100;
  overflow-x: hidden;
  overflow-y: auto;

  transition: all 0.3s ease;
  color: #fff;
  -webkit-overflow-scrolling: touch;
  @include media-breakpoint-up(lg) {
    transform: scale(1.1);
  }
  .modal_area {
    position: relative;
    z-index: 101;
    margin: 50px auto;
    background-color: #000;
    padding: 70px 30px;
    width: 1000px;
    max-width: 100%;
    @include media-breakpoint-down(lg) {
      width: auto;
      margin: 50px 30px;
    }
    .close_btn {
      color: #fff;
      position: absolute;
      right: 20px;
      top: 10px;
      font-size: 1.5rem;
    }
    h2 {
      text-align: center;
      margin-bottom: 65px;
      img {
        width: 340px;
      }
    }
    & > h3 {
      text-align: center;
      font-size: 1.444rem;
      margin-top: 100px;
      margin-bottom: 68px;
    }
    .row {
      & > div {
        h4 {
          font-size: 1.444rem;
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.video_wrap {
  width: 500px;
  max-width: 100%;
}

.cont_data {
  br {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}
</style>
