var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"my_section"},[_c('div',{staticClass:"container py-4"},[_c('h2',{staticClass:"text-center mb-4"},[_vm._v("\n      NEWS\n    ")]),_c('div',{staticClass:"row post_list_area"},_vm._l((_vm.posts),function(post,i){return _c('div',{key:i,staticClass:"col-lg-4 post_list_cont"},[_c('router-link',{attrs:{"to":{ name: 'article', params: { id: post.id } }}},[_c('div',{staticClass:"thumb_area"},[_c('img',{attrs:{"src":post.better_featured_image.source_url,"alt":""}})]),_c('div',{staticClass:"detail_area"},[_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm.formatDate(post.date)))]),_c('h3',{domProps:{"innerHTML":_vm._s(post.title.rendered)}})])])],1)}),0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-left"},[(_vm.page - 1 > 0)?_c('router-link',{attrs:{"to":{
            name: 'blog',
            query: { page: _vm.page - 1 },
          }}},[_vm._v("\n          前のページへ\n        ")]):_vm._e()],1),_c('div',{staticClass:"col text-center"},[_vm._v(_vm._s(_vm.page)+" / "+_vm._s(_vm.totalPages))]),_c('div',{staticClass:"col text-right"},[(_vm.page < _vm.totalPages)?_c('router-link',{attrs:{"to":{
            name: 'blog',
            query: { page: _vm.page + 1 },
          }}},[_vm._v("\n          次のページへ\n        ")]):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }