<template>
  <section class="concept_area">
    <div class="container">
      <div class="row concept01" v-if="isHome">
        <div class="col-lg-auto">
          <div class="cont_data">
            <h2>CONCEPT</h2>
            <h3>新しいタイプの日本酒テイスティングバー</h3>
            <p class="mb-0">
              「SAKE MARKET」は、<br />
              お酒をもっと自由に、<br />
              楽しんだり、体験したり、出会ったりできる、<br />
              お酒のテーマパーク。<br />
              自由な空間で、特別な時間をお過ごしください。<br />
              SAKE MARKET" is a<br />
              To drink more freely,<br />
              Enjoy, experience and discover new sake.<br />
              Sake Theme Park<br />
              Enjoy a special time in a free space.
            </p>
          </div>
        </div>
        <div class="col-lg-5">
          <img
            class="catch01"
            src="../assets/catch01.jpg"
            alt="日本酒専門店・日本酒バーのSAKEMARKETのコンセプト写真"
          />
        </div>
      </div>
      <div class="row concept02">
        <div class="col-lg-auto" v-if="!$isSp">
          <img
            src="../assets/catch02.jpg"
            alt="日本酒専門店・日本酒バーのKURANDSAKEMARKETの楽しみ方イメージ"
          />
        </div>
        <div class="col-lg-auto">
          <div class="cont_data">
            <h2>PRICE</h2>
            <h3>
              日本酒、果実酒、梅酒、焼酎約100種類<br />
              飲み比べし放題<br />
              料理持ち込み自由
            </h3>
            <small>
              発泡酒（サントリー金麦）・サワー・ソフトドリンクもございます。
            </small>
            <p>
              You can compare about 100 kinds of sake, fruit wine, plum wine,
              shochu, etc.<br />
              Please feel free to bring your own food!<br />
              Beer, sours and soft drinks are also available.
            </p>
            <h5 class=""></h5>
            <table class="price_table">
              <tr>
                <th>
                  夜 18:00～23:00（L.O. 22:30 最終入店時間 22:15）
                  <div>時間無制限</div>
                </th>
                <td>
                  ¥3,600（税込）
                </td>
              </tr>
              <tr>
                <th>
                  90分
                </th>
                <td>
                  ¥2,500（税込）
                </td>
              </tr>
              <tr>
                <th>
                  30分
                </th>
                <td>
                  ¥1,500（税込）
                </td>
              </tr>
              <tr>
                <th>
                  昼 (土日祝のみ) 12:00～16:00（L.O. 15:30 最終入店時間 15:15）
                  <div>昼飲み</div>
                </th>
                <td>
                  ¥2,500（税込）
                </td>
              </tr>
            </table>

            <p>
              Unlimited time<br />
              18:00-23:00 (L.O. 22:30) ¥3,600 (tax included)<br />
              90 min. 2,500 yen (tax included)<br />
              30 min. 1,500 yen (tax included)<br />
              For daytime drinking on Saturdays, Sundays and holidays<br />
              12:00 - 16:00 (L.O. 15:30) 2,500 yen (tax included)<br />
              Credit cards, PayPay, and LINE Pay are accepted.
            </p>
            <p class="mb-5">
              クレジットカード・PayPay・LINE Pay使えます。<br />
            </p>

            <!-- <button class="my_btn" @click.prevent="showAndScroll">
              web予約でおつまみ1品プレゼント！
              <i class="fas fa-chevron-right"></i>
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    isHome() {
      return this.$route.name === 'home'
    },
  },
  methods: {
    showAndScroll() {
      this.$store.commit('changeModal', true)
      setTimeout(() => {
        $('.modal_mask').scrollTop($('.modal_area h3').offset().top)
      }, 100)
    },
  },
}
</script>
